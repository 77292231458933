<script>
  import Poles from "./poles.svelte";
    let bde = [
      { src: "../images/bureau/louisC.jpg", alt: "Image 1", title: "Commandant de bord", subtitle: 'Louis C "12=9"', text: "Hiérarchie, vous connaissez ?" },
      { src: "../images/bureau/eva.jpg", alt: "Image 2", title: "Copilote", subtitle: 'Eva "pas le temps"', text: "Elle court partout, elle est tout le temps occupée, elle fait tout, bref, elle a pas le temps." },
      { src: "../images/bureau/khiem.jpg", alt: "Image 3", title: "Copilote", subtitle: 'Khiem "Poppy ?"', text: "Si vous en cherchez encore c'est que vous ne l'avez pas croisé" },
      { src: "../images/bureau/meven.jpg", alt: "Image 4", title: "Satellite", subtitle: 'Meven "Space Mountain"', text: "Ça moooooonte et ça redescend. Mais ça monte bien à la tête surtout." },
      { src: "../images/bureau/olympe.jpg", alt: "Image 5", title: "Satellite", subtitle: 'Olympe "ptitemontagne"', text: "Tu peux essayer de la charmer et lui faire des beaux poèmes d'amour, elle est là que pour ton argent." },
      { src: "../images/bureau/JP.jpg", alt: "Image 6", title: "Radio", subtitle: 'Jean-philibert "KTAstrophe"', text: "JP pour les intimes, mais tu peux l'appeler le crack" },
    ];
  
  </script>
  
  <section>
    <div class="container">
      {#each bde as image}
      <div class="image-container">
        <h1 class="title">{image.title}</h1>
  
        <div class="card-body">
          <h1 class="subtitle">{image.subtitle}</h1>
          <p class="text">{image.text}</p>
        </div>
          <img src={image.src} alt={image.alt} />  
      </div>
      {/each}
    </div>
  
    <Poles />
  
  </section>
  
  <style>
    
    section {
    font-size: 0.6rem;
  }

  @media (min-width: 600px) {
    section {
      font-size: 0.9rem;
    }
  }

  @media (min-width: 900px) {
    section {
      font-size: 1.4rem;
    }
  }

  section {
      height: auto;
      width: 160%;
      margin: 0rem;
      overflow: hidden;
  }


  section {
      height: auto;
      width: 160%;
      margin: 0rem;
      overflow: hidden;
  }
  
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  
  }
  
  
  
  img {
      width: 100%;
      height: 100%;
      object-fit: cover;
  }
  
  .title {
    position: absolute;
    right: 0.5em;
    top: 0.5em;
    transform: translateX(150%);
  }
  
  .card-body {
    position: absolute;
    bottom: 0;
    color: white;
    padding: .5em 1em;
    background: linear-gradient(transparent, #007AAB);
    text-shadow: 3px 3px 3px rgba(0,0,0,.3);
    transform: translateY(150%);
    transition: transform 0.3s ease;
  }
  
  .card-body:hover .subtitle::after {
    transform: scaleX(1);
  }
  
  .subtitle {
    position: relative;
  }
  
  .subtitle::after {
    content: '';
    height: .3em;
    width: 100%;
    position: absolute;
    background-color: white;
    top: 100%;
    left: 0;
    transform: scaleX(0);
    transition: transform .5s ease;
    transform-origin: left;
  }
  
  .text {
  }
  
  .image-container {
    position: relative;
      width: calc(100% / 3);
      height: auto;
      margin-bottom: 20px;
      overflow: hidden;
      border-radius: 5px;
    }
  
    .image-container:hover .card-body {
      transform: translateY(0);
    }
  
  .image-container:hover .title {
    transform: translateX(0);
    transition: transform 0.3s ease;
  }
  
  
  </style>