<script lang="ts">
  import { fade } from 'svelte/transition';
  import { inview } from 'svelte-inview';

  let isInView;
</script>

<div
  class="wrapper"
  use:inview={{ unobserveOnEnter: true, rootMargin: '-20%' }}
  on:change={({ detail }) => {
    isInView = detail.inView;
  }}
>
  {#if isInView}
  <div in:fade class="box">
    <div class="image-container">
      <div class="card-body">
        <h1>Alpes</h1>
        <p>Rien de mieux pour commencer
          l'année que de descendre la piste
          arc en ciel avec votre BDE Asteria!
          Au programme dans les Alpes
          d'huez, du ski, de la détente et la
          rencontre de votre BDE
          décontracté!</p>
      </div>
      <img 
        class="main-image" 
        src="../images/voyages/alpes.jpg" 
        alt="alpes"
      >
    </div>
  </div>
{/if}
</div>

<style>

  h1, p {
    font-family: Futura;
  }

  h1 {
    position: relative;
  }
  

  .wrapper {
    margin-top: 30px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
  }

  .box {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .card-body {
    position: absolute;
    bottom: 0;
    color: white;
    padding: .5em 1em;
    width: 580px;
    background: linear-gradient(transparent, black);
    text-shadow: 3px 3px 3px rgba(0,0,0,.3);
    transform: translateX(150%);
    transition: transform 1s ease;
  }

  .image-container:hover .card-body {
    transform: translateX(0);
  }

  .image-container {
    position: relative;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    border-radius: 15px;
  }

  .main-image {
    width: 300px;
    height: 600px;
    object-fit: cover;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }

  .image-container:hover .main-image {
    width: 600px;
    height: 600px;
    object-fit: cover;
    overflow: hidden;
  }
  
</style>