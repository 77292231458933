<script>

	let hover = false;

	function toggleHover() {
		hover = !hover 
	}

	function scrollToTarget(event) {
		event.preventDefault();
		const targetId = event.currentTarget.getAttribute("href").slice(1);
		const target = document.getElementById(targetId);
		target.scrollIntoView({ behavior: "smooth", block: "start" });
	}
</script>

	<div class='astronaute'>
		<a href="#Astronautes" on:click={scrollToTarget}>
			<img src="../images/astronaute.webp" alt="astronaute">	
			<p class="titre-astre"> astronautes</p>
		</a>
	</div>

	<div class='spaceShuttle'>
		<a href="#Voyages" on:click={scrollToTarget}>
			<img class='size' src="../images/Space-Shuttle.webp" alt="fusé">	
			<p class="titre-ship"> Voyages</p>
		</a>
	</div>


	<div class="position" id="parent">
		<div class= "planets-container">
			<div class="sun">
				<div on:mouseenter={toggleHover} on:mouseleave={toggleHover}>
					<img class='logo' src="../images/logo.png" alt="logo" >
				</div>
			</div>

			
			<div class= "planet-index" id="first-index" style="pointer-events: none;">
				<div class="route" style="pointer-events: none;">
					<div class="planet-container" id="first-container" style="pointer-events: auto;">
						<a href="#Video" on:click={scrollToTarget}>
							<div class="planet" id="first-planet" >						
								<p class="titre">Video</p>			
							</div>	
						</a>
					</div>
				</div>
			</div>
			

			<div class= "planet-index" id="second-index" style="pointer-events: none;">
				<div class="route" style="pointer-events: none;">
					<div class="planet-container" id="second-container" style="pointer-events: auto;">
						<a href="#Défis" on:click={scrollToTarget}>
							<div class="planet" id="second-planet">
								<p class="titre">Defis</p>
							</div>
						</a>
					</div>
				</div>		
			</div>

			<div class= "planet-index" id="third-index" style="pointer-events: none;">
				<div class="route" style="pointer-events: none;">
					<div class="planet-container" id="third-container" style="pointer-events: auto;">
						<a href="#Sponsors" on:click={scrollToTarget}>
							<div class="planet" id="third-planet">
								<p class="n titre">Sponsors</p>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>


<style>

	#parent {
		position: relative;
		display: flex;
		align-items: center;
    	justify-content: center;
		transform: scale(0.6) translateY(50%);

	}

	a {
		text-decoration: none;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.titre-ship {
		position: absolute;
        font-weight: 700;
		left: 35%;
		bottom: 10%;
        color: white;
        text-decoration: none;
        font-size: 1rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        transition: 0.6;
	}

	.titre-astre {
		position: absolute;
        font-weight: 700;
		left: 40%;
        color: white;
        text-decoration: none;
        font-size: 1rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        transition: 0.6;
	}

	.astronaute {
        position: absolute;
        top: 1em;
        left: 5em;
        width: 15em;
        height: 15em;
        animation: breathe 3s ease-in-out infinite;
		transition: all 0.2s ease-in-out;
    }


	.spaceShuttle {
		position: absolute;
		top: -2rem;
		right: 0;
	}
	.size {
		width: 300px;
        height: 300px;
	}

	.position {
		transform: translateY(120%);
	}

	.planets-container {
		width: 280px;
		height: 280px;
		border-radius: 50%;
		transform: scaleX(5);
	}

	.sun {
		transform: scaleX(0.2);
	}

	.logo {
		position: absolute;
		bottom: -250px;
		left: 50%;
		transform: translateX(-50%);
	    width: 339px;
		height: 373px;
		border-radius: 50%;
		background-color: black;
		box-shadow: 0 0 60px rgb(253,203,163), 0 0 98px rgb(184, 160, 26); 
		animation: breathe 3s ease-in-out infinite;
		transition: all 0.2s ease-in-out;
	}

	.planet {
		position: absolute;
		width: 100px;
		height: 100px;
		border-radius: 50%;
		background-color: red;
		transform: scaleX(0.2);
	}


	p {
		display: none;
	}
	div:hover p {
		display: block;
	}
	.titre {
		position: relative;
        font-weight: 500;
        color: white;
        text-decoration: none;
        font-size: 2rem;
        text-transform: uppercase;
        letter-spacing: 2px;
		top: 100px;
	}
	.n {
		position: absolute;
		top: 100px;
		left: -40px;
	}

    .planet:after {
        content: '';
        position: absolute;
        inset: 0px;
        border-radius: 50%;
    }

    #first-planet {
        background-color: blue;
        animation: firstPlanetRender 12s infinite linear;
    }
    #first-planet:after {
		background-color: rgb(0, 0, 180);
        animation: firstPlanetRenderRot 12s infinite linear;
	}

	#second-planet {
		background-color: red;
	}
	#third-planet {
		background-color: green;
	}

	.route {
		width: 100%;
		height: 100%;
		animation: rotate 12s infinite linear;
		position: absolute;
	}

	.planet-container {
		width: 100px;
		height: 100px;
		animation: correct 12s infinite linear;
		position: absolute;
	}

	#second-container {
		left: 200px;
	}

	#third-container {
		left: 100px;
        top: 240px

	} 

	.planet-index {
		width: 100%;
		height: 100%;
		position: absolute;
	}

	#first-index {
		animation: hideFirstPlanet 12s infinite;
	}

	#second-index {
		animation: hideSecondPlanet 12s infinite;
	}

	#third-index {
		animation: hideThirdPlanet 12s infinite;
	}

    @keyframes firstPlanetRender {
        0% {
            background: linear-gradient( -90deg, rgb(86,86,253) 50%, rgb(60,60,175) 50%);
        }
        12% {
            background: linear-gradient( -90deg, rgb(86,86,253) 50%, rgb(60,60,175) 50%);
        }
        12.1% {
            background: linear-gradient( 90deg, rgb(86,86,253) 50%, rgb(60,60,175) 50%);
        }
        62% {
            background: linear-gradient( 90deg, rgb(86,86,253) 50%, rgb(60,60,175) 50%);
        }
        62.1% {
            background: linear-gradient( -90deg, rgb(86,86,253) 50%, rgb(60,60,175) 50%);
        }
        100% {
            background: linear-gradient( -90deg, rgb(86,86,253) 50%, rgb(60,60,175) 50%);
        }
    }

    @keyframes firstPlanetRenderRot {
        0% {transform: scaleX(-0.2); background-color: rgb(86, 86, 253);}
        12% {transform: scaleX(-1); background-color: rgb(86, 86, 253);}
        12.1% {transform: scaleX(1); background-color: rgb(86, 86, 253);}

        37% {transform: scaleX(0); background-color: rgb(86, 86, 253);}
        37.1% {transform: scaleX(0); background-color: rgb(60, 60, 175);}

        62% {transform: scaleX(-1); background-color: rgb(60, 60, 175);}
        62.1% {transform: scaleX(1); background-color: rgb(60, 60, 175);}

        87% {transform: scaleX(0); background-color: rgb(60, 60, 175);}
        87.1% {transform: scaleX(0); background-color: rgb(86, 86, 253);}

        100% {transform: scaleX(-0.2); background-color: rgb(86, 86, 253);}
    }

	@keyframes rotate {
		0% {
			transform: rotateZ(0deg);
		}
		100% {
			transform: rotateZ(360deg);
		}
	}

	@keyframes correct {
		0% {
			transform: rotateZ(360deg);
		}
		100% {
			transform: rotateZ(0deg);
		}
	}

	@keyframes breathe {
		0% {
			transform: translateX(-50%) scale(1);
		}
		50% {
			transform: translateX(-50%) scale(1.1);
		}
		100% {
			transform: translateX(-50%) scale(1);
		}
	}
	
	@keyframes hideFirstPlanet {
		0% {
			z-index: 999;
            transform: scale(0.7);

		}
		1% {
			z-index: -999
		}

        75% {
            transform: scale(1.1);
        }

		100% {
			z-index: 999;
            transform: scale(0.7);
		}
	}

	@keyframes hideSecondPlanet {
		0% {
			z-index: -999;
            transform: scale(0.8);
		}

        40% {
            transform: scale(1.1);
        }
		60% {
			z-index: 999;
		}

        65% {
            transform: scale(0.8);
        }

        80% {
            transform: scale(0.7);
        }

		100% {
			z-index: -999;
            transform: scale(0.8);
		}
	}

	@keyframes hideThirdPlanet {
		0% {
			z-index: 999;
            transform: scale(1.1);
		}
		65% {
			z-index: -999;
            transform: scale(0.7);
		}

		100% {
			z-index: 999;
            transform: scale(1.1);
		}
	}

</style>