<script>
	import Hub from './hub.svelte';
	import Footer from './footer.svelte';
	import Navbar from './navbar.svelte';
	import Stars from './stars.svelte'

	import Voyages from './voyages.svelte';
	import Astronautes from './astronautes.svelte';
	import Defis from './defis.svelte';
	import Sponsors from './sponsors.svelte';
	import Video from './video.svelte';

	import { onMount } from 'svelte';

	let showLoadingScreen = true;

	setTimeout(() => {
		showLoadingScreen = false;
	}, 3000);

	let height;
	let width;

	onMount(() => {
		height = window.innerHeight;
		width = window.innerWidth;
	})

</script>


<main>
	<Navbar />
	<body>
		<div class="black top" id="Accueil">
			<!-- <Stars /> -->
			<Hub />
		</div>
 
		<div class="spacer layer1"></div> 

		<section class="blue" id="Astronautes" >
			<h1>Astronautes</h1>
			<Astronautes />
		</section>

		<div class="spacer layer2"></div> 

		<section class="pink" id="Video" >
			<h1>Video de campagne</h1>
			<Video />
		</section>

		<div class="min-spacer layer3"></div> 

		<section class="dark" id="Voyages" >
			<h1>Voyages</h1>
			<Voyages />
		</section>
		<section id="Défis" >
			<h1>Défis</h1>
			<Defis />
		</section>

		<div class="spacer layer4"></div> 

		<section id="Sponsors" >
			<h1>Sponsors</h1>
			<Sponsors />
		</section>

		<Footer />
	</body>

		
</main>

<style>
	#Accueil {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 100px 20vw;
	}

	.top {
		margin-top: 60px;
	}

	main {
		outline: none;
		margin: 0;
		padding: 0;
	}
	
	body {
		outline: none;
		margin: 0;
		padding: 0 !important;
		justify-content: center;
		color: white;
		background-color: #007AAB;
		overflow: hidden;
	}

	h1 {
        position: relative;
        font-weight: 700;
        color: white;
        text-decoration: none;
        font-size: 2rem;
        text-transform: uppercase;
        letter-spacing: 2px;
        transition: 0.6;
    }

	section {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		min-height: 600px;
		padding: 100px 20vw;
	}



	.blue {
		background: #007AAB
	}
	.pink {
		background: #B0235F
	}
	.dark {
		background: #222222
	}

	.black {
		background: #222222;
	}


	.min-spacer {
		aspect-ratio: 960/150;
		width: 100%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		background-color: transparent;
	}

	.spacer {
		aspect-ratio: 960/300;
		width: 100%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		background-color: transparent;
		
	}

	.layer1 {
		background-image: url('../images/dark-blue-waves.svg');
		
	}

	.layer2 {
		background-image: url('../images/stacked-steps.svg');
	}

	.layer3 {
		background-image: url('../images/wave.svg');
	}

	.layer4 {
		background-image: url('../images/jagged.svg');
	}

	@media (min-width: 640px) {
		main {
			max-width: none;
		}
	}
</style>