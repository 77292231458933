<script>
    import Youtube from "svelte-youtube-embed";
</script>


    <div>
        <Youtube id="8wCoWS2nhiw"/>
    </div>

<style>



</style>