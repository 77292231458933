<script>
</script>

<div class="footer">
	<div class="footer-title">Nos réseaux</div>
	<div class="social-media-links">
	  <a href="https://www.instagram.com/liste_asteria" target="_blank">Instagram</a>
	  <a href="https://www.facebook.com/liste.bde.asteria" target="_blank">Facebook</a>
	  <a href="https://www.youtube.com/@Liste_Asteria" target="_blank">YouTube</a>
	  <a href="mailto:asteria95ppm@gmail.com" target="_blank">Email</a>
	  <a href="https://t.me/Liste_Asteria" target="_blank">Telegram</a>
	</div>
  </div>
  
  

  <style>

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #222222;
}

.footer-title {
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.social-media-links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.social-media-links a {
  color: #ffffff;
  text-decoration: none;
  margin: 0 10px;
  font-size: 18px;
  transition: all 0.3s ease-in-out;
}

.social-media-links a:hover {
  color: #f4c20d;
  transform: scale(1.1);
}

  
</style>