<style>
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .box {
    width: calc(33.33% - 10px);
    height: 200px;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    .box {
      width: calc(50% - 10px);
    }
    img {
    width: 10px;
  }
  }

  @media (max-width: 480px) {
    .box {
      width: 100%;
    }
    img {
    width: 50px;
  }
  }


  img {
    width: 150px;
  }
</style>

<div class="container">
  <div class="box">
    <img src="../images/sponsors/image1.png" alt="Image 1">
  </div>
  <div class="box">
    <img src="../images/sponsors/image2.png" alt="Image 2">
  </div>
  <div class="box">
    <img src="../images/sponsors/image3.png" alt="Image 3">
  </div>
  <div class="box">
    <img src="../images/sponsors/image4.png" alt="Image 4">
  </div>
  <div class="box">
    <img src="../images/sponsors/image5.png" alt="Image 5">
  </div>
  <div class="box">
    <img src="../images/sponsors/image6.png" alt="Image 6">
  </div>
  <div class="box">
    <img src="../images/sponsors/image7.png" alt="Image 7">
  </div>
  <div class="box">
    <img src="../images/sponsors/image8.png" alt="Image 8">
  </div>
  <div class="box">
    <img src="../images/sponsors/image9.png" alt="Image 9">
  </div>
</div>
