<script>
  import { onMount } from "svelte";
  
  let apiData = null;
  let defiReal = null;

  onMount(async () => {
    const response = await fetch("/api/defi");
    const data = await response.json();
    apiData = data.data;

    const response2 = await fetch("/api/realise");
    const data2  = await response2.json();
    defiReal = data2.data;
  });

  let pageNumber = 1;
  let pageSize = 15;

  function changePage(newPageNumber) {
    pageNumber = newPageNumber;
  }


  let showForm = false;
  let confirmReq = false

  function handleForm() {
    confirmReq = false;
  }

  async function handleSubmit() {
    event.preventDefault();
    const formData = new FormData(event.target);
    const response = await fetch(`api/add/${formData.get('param1')}/${formData.get('param2')}/${formData.get('h-captcha-response')}`);
    const data = await response.json();
    console.log(data);
    if (data.result=='Succes') {
      confirmReq = true;
      console.log('Défi envoyé!!!')
    }

  }

  let captcha = false

  function handleSuccess() {
    captcha = true
  }
</script>

{#if apiData}
  <h1>Défi proposé</h1>
  <table>
    <thead>
      <tr>
        <th>Proposé par</th>
        <th>Défi</th>
      </tr>
    </thead>
    <tbody>
      {#each apiData.slice((pageNumber - 1) * pageSize, pageNumber * pageSize) as item}
        <tr>
          <td>{item[0]}</td>
          <td>{item[1]}</td>
        </tr>
      {/each}
    </tbody>
  </table>
  {#if apiData.length > pageSize}
    <div>
      {#if pageNumber > 1}
        <button on:click={() => changePage(pageNumber - 1)}>&lt;</button>
      {/if}
      Page {pageNumber} of {Math.ceil(apiData.length / pageSize)}
      {#if pageNumber * pageSize < apiData.length}
        <button on:click={() => changePage(pageNumber + 1)}>&gt;</button>
      {/if}
    </div>
  {/if}
{/if}



  <form on:submit={handleSubmit}>
    <p>Nom</p>
    <input type="text" name="param1" required />
    <p>Défis</p>
    <input type="text" name="param2" required />
    <button type="submit">Submit</button>
    <div class="h-captcha" data-sitekey='ed8a0d76-9dbb-4e60-9ce0-ca2bc3865c10'></div>
  </form>



{#if confirmReq}
  <p>Défi envoyé!!!</p>
{/if}


{#if defiReal}
  <h1>Défi realisé</h1>
  <table>
    <thead>
      <tr>
        <th>Proposé par</th>
        <th>Défi</th>
      </tr>
    </thead>
    <tbody>
      {#each defiReal.slice((pageNumber - 1) * pageSize, pageNumber * pageSize) as item}
      <tr>
        <td>{item[0]}</td>
        <td><a href="{item[2]}" style="color: white;" target="_blank">{item[1]}</a></td>
      </tr>
    {/each}
    </tbody>
  </table>
  {#if apiData.length > pageSize}
    <div>
      {#if pageNumber > 1}
        <button on:click={() => changePage(pageNumber - 1)}>&lt;</button>
      {/if}
      Page {pageNumber} of {Math.ceil(defiReal.length / pageSize)}
      {#if pageNumber * pageSize < defiReal.length}
        <button on:click={() => changePage(pageNumber + 1)}>&gt;</button>
      {/if}
    </div>
  {/if}
{/if}





<style>



  table {
    width: 100%;
    border-collapse: collapse;
  }

  thead {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    position: sticky;
    top: 0;
  }

  th, td {
    border: 1px solid black;
    padding: 8px;
  }

  th:first-child, td:first-child {
    max-width: 20%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
  }

  td:last-child {
    width: 80%;
    background-color: rgba(0, 0, 0, 0.2);
  }

  tbody {
    max-height: 15em;
    overflow-y: scroll;
    display: table-row-group;
    width: 100%;
  }
</style>