<script>
    import { slide } from 'svelte/transition';


    function scrollToTarget(event) {
        event.preventDefault();
        const targetId = event.target.getAttribute("href").slice(1);
        const target = document.getElementById(targetId);
        target.scrollIntoView({ behavior: "smooth", block: "start" });
    }



</script>

    <header transition:slide>
        <a href="#" class="logo">ASTERIA</a>
        <ul>
            <li><a href="#Accueil" on:click={scrollToTarget}>Accueil</a></li>
            <li><a href="#Astronautes" on:click={scrollToTarget}>Astronautes</a></li>
            <li><a href="#Video" on:click={scrollToTarget}>Video</a></li>
            <li><a href="#Voyages" on:click={scrollToTarget}>Voyages</a></li>
            <li><a href="#Défis" on:click={scrollToTarget}>Défis</a></li>
            <li><a href="#Sponsors" on:click={scrollToTarget}>Sponsors</a></li>
        </ul>
    </header>


<style>
    header {
        position: fixed;
        top: 0;
        left: 50;
        width: 92%;
        
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: 0.6s;
        padding: 10px 100px;
        z-index: 1000000;
        background-color: rgba(5,5,5,.5);
    }

    @media (max-width: 768px) {
        header {
            width: 100%;
            padding: 10px 20px;
        }
    }


    header .logo {
        position: relative;
        font-weight: 700;
        color: white;
        text-decoration: none;
        font-size: 2rem;
        text-transform: uppercase;
        letter-spacing: 2px;
        transition: 0.6;
    }

    ul {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
    }

    @media (max-width: 768px) {
        ul {
            display: none;
            position: absolute;
            z-index: 1;
            background-color: transparent;
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            padding: 12px 16px;
        }

        header:hover ul {
            position: relative;
            display: block;
        }
        
    }

    li {
        position: relative;
        list-style: none;
    }

    a {
        position: relative;
        margin: 0 15px;
        text-decoration: none;
        color: white;
        letter-spacing: 2px;
        font-weight: 500px;
        
    }

</style>