<script>

    import { fade } from 'svelte/transition';
    import { slide } from 'svelte/transition';
    import { fly } from 'svelte/transition';


    let showPoleImages = true;
    let selectedPole;
    let poleName;

    window.addEventListener("click", handleWindowClick);

    function poleSelection() {
        event.stopPropagation();
    }

    function handleWindowClick() {
        showPoleImages = true;
    }

    function selectPole(id) {
        displayPole(id);
        poleName = id;
        showPoleImages = !showPoleImages;
        event.stopPropagation();
    }

    let poleTrack = 0;
    let poleNames = [
        'Supernova - Pôle soirée','Les comètes - Pôle event',"Tour de contrôle - Pôle comm'",
        'Tatooine - Pôle entreprise','Navette spaciale - Pôle voyage','Trou noir']


    let pole = [
        {id: 'polesoiree', src: "../images/poles/soiree.jpg", alt: "Image 1", title: "Supernova - Pôle soirée"},
        {id: 'poleevent', src: "../images/poles/event.jpg", alt: "Image 2", title: "Les comètes - Pôle event"},
        {id: 'polecom', src: "../images/poles/com.jpg", alt: "Image 3", title: "Tour de contrôle - Pôle comm'"},
        {id: 'poleentreprise', src: "../images/poles/entreprise.jpg", alt: "Image 4", title: "Tatooine - Pôle entreprise"},
        {id: 'polevoyage', src: "../images/poles/voyage.jpg", alt: "Image 5", title: "Navette spaciale - Pôle voyage"},
        {id: 'trounoir', src: "../images/poles/trounoir.jpg", alt: "Image 6", title: "Trou noir"},
    ];

    function displayPole(pole) {
        switch (pole) {
            case "polesoiree":
                poleTrack = 0
                selectedPole = [
                    { src: "../images/soiree/antoine.jpg", alt: "Image 1", title: "Respo Soirée", subtitle: 'Antoine "Vincent"', text: "Appelez le Vincent, c'est comme ça qu'il s'appelle maintenant. Ça lui va tellement mieux en même temps..." },
                    { src: "../images/soiree/lucile.jpg", alt: "Image 2", title: "Pôle soirée", subtitle: 'Lucile "pasderaclette"', text: "Elle adore dormir, à tel point qu'elle se souvient pas de la raclette." },
                    { src: "../images/soiree/laura.jpg", alt: "Image 3", title: "Pôle soirée", subtitle: 'Laura "propa29"', text: "La mer froide, les mouettes, ne lui parlez pas de beurre doux seul le beurre salé existe." },
                    { src: "../images/soiree/adrix.jpg", alt: "Image 4", title: "Pôle soirée", subtitle: 'Adrien "400 balles"', text: "Un peu shy le jour mais Dj la nuit, Adrix sait faire danser les foules pour plus ou moins 4OO balles" },
                    { src: "../images/soiree/mathis.jpg", alt: "Image 5", title: "Pôle soirée", subtitle: 'Mathis "capote YSL"', text: "Mathis, le mec le moins shy de la CY, cheveux long, cheveux lisse, avec lui tout glisse, il ken (ta maman) en capote Yves Saint Laurent" },
                ];
                break;
            case "poleevent":
                poleTrack = 1
                selectedPole = [
                    { src: "../images/event/lou.jpg", alt: "Image 1", title: "Respo Event", subtitle: 'Lou "berlingo"', text: "Elle en est fière de son bolide." },
                    { src: "../images/event/joella.jpg", alt: "Image 2", title: "Pôle event", subtitle: 'Joella "santa claus"', text: "Votre mère noël préférée même si elle fait que 1m10." },
                    { src: "../images/event/celestine.jpg", alt: "Image 3", title: "Pôle event", subtitle: 'Célestine "la staaar"', text: "Tout pour les caméras, lumineuse en toute circonstance." },
                    { src: "../images/event/antonin.jpg", alt: "Image 4", title: "Pôle event", subtitle: 'Antonin "kiroulebamboule"', text: "Un mélange de gentillesse de duper et de vodsky." },
                    { src: "../images/event/marion.jpg", alt: "Image 5", title: "Pôle event", subtitle: 'Marion "dauphine"', text: "C'est beau d'avoir des rêves..." },
                    { src: "../images/event/youssef.jpg", alt: "Image 6", title: "Pôle event", subtitle: 'Youssef "retardataire"', text: "On a rarement vu un retard aussi conséquent." },
                ];
                break;
            case "polecom":
                poleTrack = 2
                selectedPole = [
                    { src: "../images/com/elya.jpg", alt: "Image 1", title: "Respo Comm'", subtitle: 'Elya "Larma Croft"', text: "Elle serait capable de noyer ses ennemis." },
                    { src: "../images/com/lorie.jpg", alt: "Image 2", title: "Pôle Comm'", subtitle: 'Lorie "Bobby"', text: "Elle ne cache pas de calvitie c’est juste que c’est la classe." },
                    { src: "../images/com/loucas.jpg", alt: "Image 3", title: "Pôle Comm'", subtitle: 'Loucas "MBacclé"', text: "Si vous croisez Mbappe, il est sûrement dans les parages …" },
                    { src: "../images/com/riwan.jpg", alt: "Image 4", title: "Pôle Comm'", subtitle: 'Riwan "Trunks"', text: "C'est l'incarnation humaine du meme de spiderman. Miaou" },
                    { src: "../images/com/sylvain.jpg", alt: "Image 5", title: "Pôle Comm'", subtitle: 'Sylvain "Ragnar"', text: "150 au compteur, le Ragnar n’a pas peur. 6 mois de suspension ça fait mal au fion." },
                ];
                break;
            case "poleentreprise":
            poleTrack = 3
                selectedPole = [
                    { src: "../images/entreprise/benjamin.jpg", alt: "Image 1", title: "Respo Entreprise", subtitle: 'Benjamin "Skinny B*tch"', text: "Vous avez déjà vu son cul ?? Le sport ça paye (les squats surtout)." },
                    { src: "../images/entreprise/omar.jpg", alt: "Image 2", title: "Pôle Entreprise", subtitle: 'Omar "Wado"', text: "Aucune explication, c'est son surnom." },
                    { src: "../images/entreprise/baptiste.jpg", alt: "Image 3", title: "Pôle Entreprise", subtitle: 'Baptiste "ouémachmiz"', text: "èl es ou mah chmiz ?" },
                    { src: "../images/entreprise/gautier.jpg", alt: "Image 4", title: "Pôle Entreprise", subtitle: 'Gautier "Ricard(o)"', text: "On sait pas si c’est un oncle mexicain ou s'il est alcoolo." },
                    { src: "../images/entreprise/louisM.jpg", alt: "Image 5", title: "Pôle Entreprise", subtitle: 'Louis M "trenbolone"', text: "Avec ses faux muscles là." },
                ];
                break;
            case "polevoyage":
            poleTrack = 4
                selectedPole = [
                    { src: "../images/voyage/augustine.jpg", alt: "Image 1", title: "Respo Voyage", subtitle: 'Augustine "meth(ionine)"', text: "méthionine ou méthamphétamine on ne sait pas vraiment, leurs points communs : leurs effets dévastateurs." },
                    { src: "../images/voyage/clement.jpg", alt: "Image 2", title: "Pôle Voyage", subtitle: 'Clément "moustache"', text: "Hercule Poirot en était jaloux, il a dû la raser." },
                    { src: "../images/voyage/ludivine.jpg", alt: "Image 3", title: "Pôle Voyage", subtitle: 'Ludivine "hypoglycémie"', text: "Elle sait s'évanouir avec style." },
                ];
                break;
            case "trounoir":
            poleTrack = 5
                selectedPole = [
                    { src: "../images/trounoir/adam.jpg", alt: "Image 1", title: "Respo Trou noir", subtitle: 'Adam "List`1cteur"', text: "Il a réussi à éteindre la flamme de sa propre liste." },
                    { src: "../images/trounoir/AM.jpg", alt: "Image 2", title: "Pôle Trou noir", subtitle: 'Abdel-Malek "Akhy Potter"', text: "Comme un sorcier il apparaît et disparaît à sa guise." },
                ];
                break;

        }
    }

</script>

<h1 class="debut">LES POLES</h1>

{#if showPoleImages}
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div class="container" transition:fade>
        {#each pole as image}
            <div class="image-container" on:click={() => selectPole(image.id)}>

                <h1 class="title">{image.title}</h1>

                <img class="display" src={image.src} alt={image.alt} />  

            </div>
        {/each}
    </div>
{/if}

{#if !showPoleImages}


    <h1 transition:fly="{{ y: 3000, duration: 3000 }}">{poleNames[poleTrack]}</h1>
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div class="pole-section"  on:click={poleSelection} transition:fly="{{ y: 3000, duration: 3000 }}">
        {#each selectedPole as image}
            <div class="pole-container">
                <h1 class="pole-title">{image.title}</h1>
                
                <div class="pole-card">
                    <h1 class="pole-subtitle">{image.subtitle}</h1>
                    <p class="pole-text">{image.text}</p>
                </div>

                <img class="pole-image" src={image.src} alt={image.alt} />
            </div>
        {/each}
    </div>
{/if}

<style>
    .debut {
        position: relative;
        font-weight: 700;
        color: white;
        text-decoration: none;
        font-size: 2rem;
        text-transform: uppercase;
        letter-spacing: 2px;
        transition: 0.6;
    }

    .pole-section {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
    }
    .pole-container {
        position: relative;
        width: calc(100% / 3);
        height: auto;
        margin-bottom: 20px;
        overflow: hidden;
        border-radius: 15px;
    }
    .pole-container:hover .pole-card {
        transform: translateY(0);
    }
    .pole-container:hover .pole-title {
        transform: translateX(0);
        transition: transform 0.3s ease;
    }
    .pole-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .pole-title {
        position: absolute;
        right: 0.5em;
        top: 0.5em;
        transform: translateX(150%);
    }
    .pole-card {
    position: absolute;
    bottom: 0;
    color: white;
    padding: .5em 1em;
    background: linear-gradient(transparent, #007AAB);
    text-shadow: 3px 3px 3px rgba(0,0,0,.3);
    transform: translateY(150%);
    transition: transform 0.3s ease;
    }
    .pole-card:hover .pole-subtitle::after {
    transform: scaleX(1);
    }
    .pole-subtitle {
    position: relative;
    }
    .pole-subtitle::after {
    content: '';
    height: .3em;
    width: 100%;
    position: absolute;
    background-color: white;
    top: 100%;
    left: 0;
    transform: scaleX(0);
    transition: transform .5s ease;
    transform-origin: left;
    }
    .pole-text {
    }
    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        height: auto;
    }
    .display {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: filter 0.5s;
    }
    .title {
        font-size: 200%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -100%);
        text-shadow: 3px 3px 3px rgba(0,0,0,0.5);
        opacity: 0;
        transition: opacity 0.5s;
        z-index: 1;
    }
    .image-container:hover .title {
        opacity: 1;
    }
    
    .image-container {
        position: relative;
        width: calc(100% / 3);
        height: auto;
        margin-bottom: 20px;
        overflow: hidden;
        border-radius: 5px;
        background-color: red;
    }
    .image-container:hover img {
        filter: blur(5px);
    }
</style>