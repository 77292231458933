<script>
import Wei from "./voyages/wei.svelte";
import Amsterdam from "./voyages/Amsterdam.svelte";
import AlpesDuez from "./voyages/Alpes-Duez.svelte";
</script>


    <!-- <SvgLine /> -->
    <section class="left">
        <Wei />
    </section>
    <section class="right">
        <Amsterdam />
    </section>
    <section class="left">
        <AlpesDuez />
    </section>

<style>

    section {
        display: flex;
        place-items: center;
        align-content: center;
    }
    .right {
        margin-left: auto;
    }

    .left {
        margin-right: auto;
    }
    

    
</style>